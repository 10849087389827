import React, { useState } from 'react'
import '../App.css'
import './itservices.css'
import '../media.css'

export default function ItServices({itRef}) {
  
  const check_mark = <span className='text-center mr-2'>&#10003;</span>
  const block1 = <div className='flex flex-col gap-5 p-5'>
    <p>Мы внедряем технологии искусственного интеллекта <span className='font-bold'>для автоматизации бизнес-процессов и повышения производительности</span> компании.</p>
    <p>С помощью новейших решений машинного обучения и нейросетевых моделей мы находим решения для задач вашего бизнеса:</p>
    <ul>
      <li>{check_mark}Автоматизация повседневных операций</li>
      <li>{check_mark}Предсказание настроения и настроения клиентов</li>
      <li>{check_mark}Контроль качества продукции через видеоаналитику</li>
      <li>{check_mark}Автоматическая обработка документов</li>
      <li>{check_mark}Персонализированные рекомендации и предложения</li>
      <li>{check_mark}Распознание и обработка речи и многое другое.</li>
    </ul>
    </div>
  const block2 = <div className='flex flex-col gap-5 p-5 xs:p-1'>
    <p> Комплексный анализ бизнес-процессов с применением ИИ-технологий.</p> 
    <p>Мы проводим <span className='font-bold'>детальный аудит</span> ваших бизнес-процессов, выявляя зону, где внедрение службы ИИ приносит наибольшую отдачу:</p>
    <ul>
      <li>{check_mark}Построение и моделирование процессов (BPMN и IDEF)</li>
      <li>{check_mark}Предварительный просмотр основных рабочих процессов</li>
      <li>{check_mark}Анализ возможностей применения ИИ для оценки эффективности</li>
      <li>{check_mark}Подготовка рекомендаций по внедрению искусственного интеллекта</li>
    </ul>
    <p>Наш метод позволяет не просто оценить потенциал ИИ для вашего бизнеса, а реализовать его на примере достижения максимального эффекта</p>
    </div>
  const block3 = <div className='flex flex-col gap-5 p-5'>
    <p>Автоматизация разработки и развертывания ПО с интеграцией облачных технологий.</p>
    <p>Мы предлагаем стратегию подхода к разработке, внедрению и развертыванию прогрессивных процессов программного обеспечения с адаптацией под облачную инфраструктуру.</p>
    <p>Наши решения обеспечивают плавный переход со значительными рисками и высокими уровнями:</p>
    <ul>
      <li>{check_mark}Ускорение вывода продукции на рынок</li>
      <li>{check_mark}Гибкость и масштабируемость</li>
      <li>{check_mark}Безопасность крепления</li>
      <li>{check_mark}Оптимизация затрат на инфраструктуру.</li>
    </ul>
  </div>
  const block4 = <div className='flex flex-col gap-5 p-5'>
    <p>Превращаем данные в настольный инструмент для вашего бизнеса.</p>
    <p>Мы охватываем все этапы работы, включая данные, сборку, обработку, анализ и визуализацию, помогая бизнесу принимать обоснованные решения и адаптироваться к изменениям на рынке.</p>
    <p>Подход, основанный на данных, позволяет не только оперативно реагировать на актуальные изменения, но и прогнозировать будущие изменения, повышая эффективность работы.</p>
  </div>
  const block5 = <div className='flex flex-col gap-5 p-5'>
    <p><span className='font-bold'>Нейро-ассистенты для бизнеса и клиентов. Внутренние нейроассистенты</span>: Наши ИИ-решения позволяют сократить время адаптации новых сотрудников, интегрируясь в корпоративные системы и оптимизируя рабочие процессы.</p>
    <p><span className='font-bold'>Клиентские нейро-ассистенты</span>: Разработка чат-ботов для сайтов, обеспечение оперативного взаимодействия с клиентами, улучшение обслуживания и повышение удовлетворенности пользователей.</p>
  </div>
  
  const [blockShown, setBlockShown] = useState(block1)

  const blocksArray = [block1,block2,block3,block4,block5]

  const service_1 = 'Разработка ИИ-решений'
  const service_2 = 'ИИ-консалтинг'
  const service_3 = 'DevOps и облачные решения'
  const service_4 = 'Аналитика и инженерия данных'
  const service_5 = 'Нейро-ассистенты'

  const servicesArray = [service_1,service_2,service_3,service_4,service_5]

    const findText =(i) => {
    console.log(i)
    const res = blocksArray.find((text, index) => i === index)
    console.log(res.props.children)
    setBlockShown(res)    
    }

  return (
    <div className='container_body shrink-body'>
      <p className='title my-5 font-bold text-center xl:my-2'>IT-услуги</p>
      <div className='it_table gap-14 my-20 xl:my-14 lg:my-10 xl:gap-9 lg:gap-7 md:gap-2'>
        <div className='flex flex-col justify-start'>
            {servicesArray.map((service, i)=><li className='it-title-li' onClick={()=>findText(i)} key={i}><p className='it_title rounded'>{service}</p></li> )}
        </div>
        <div className='it_text rounded'>{blockShown}</div>
      </div>
    </div>
  )
}
